.table {
  width: 100%;
  height: 100%;

  .p-paginator-current {
    @apply text-xs text-gray-500 h-10;
  }

  .p-datatable-scrollable-table > .p-datatable-thead {
    z-index: 100;
  }

  .p-datatable {
    height: 100%;
  }

  .p-datatable-wrapper {
    overflow: auto;
    height: calc(100% - 72px);
  }

  .p-datatable.p-datatable-scrollable {
    height: 100%;
  }

  .p-datatable-thead {
    z-index: 11;

    &:before {
      content: "";
      height: 1px;
      position: absolute;
      top: 0;
      width: 100%;
      @apply bg-gray-200;
    }

    tr {
      @apply drop-shadow-sm shadow-slate-200;

      th {
        @apply bg-white text-xs font-medium text-gray-800 border-t border-slate-200 px-3;

        &:not(:last-of-type) {
          @apply border-r border-slate-200;
        }
      }
    }
  }

  .p-datatable-tbody {
    tr:hover {
      @apply bg-gray-50;
    }

    tr td {
      @apply border-b border-slate-200 text-xs text-gray-600 py-2 px-3;

      &:not(:last-of-type) {
        @apply border-r border-slate-200;
      }
    }
  }

  .expansion-row {
    .expansion-data-item {
      @apply bg-gray-100 flex flex-col gap-1 rounded-md p-2 min-h-[55px] flex-grow;

      h1 {
        @apply text-gray-800 text-xs font-medium;
      }

      span {
        @apply text-gray-500 text-xs inline-block truncate;
      }
    }
  }

  .p-paginator {
    @apply justify-end;

    .p-inputwrapper {
      width: auto !important;

      .p-dropdown {
        border-radius: 0px;
      }
    }

    .p-paginator-element {
      @apply p-4 text-xs text-gray-500 border-y border-slate-200;
    }

    .p-paginator-pages .p-paginator-page.p-highlight {
      @apply bg-gray-100 border-x;
    }

    .p-paginator-first,
    .p-paginator-last {
      display: none;
    }

    .p-paginator-prev {
      @apply border-x border-slate-200;
    }
    .p-paginator-next {
      @apply border-x border-slate-200;
    }
  }
}
