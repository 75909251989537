.p-calendar {
  @apply w-full;

  .p-datepicker-header {
    @apply border-b border-slate-200;
  }

  .p-datepicker-title {
    @apply flex items-center gap-x-4;
  }

  .p-monthpicker {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2px;
    row-gap: 2px;
  }

  .p-monthpicker-month {
    @apply w-[98%];
  }
}

.p-datepicker table td > span {
  @apply text-gray-800;

  &.p-disabled {
    @apply border border-slate-100 bg-neutral-50 text-neutral-400 opacity-90 line-through;
  }
}
