.ui-btn {
  &.invalid {
    opacity: 0.6;
  }

  @apply flex items-center bg-transparent rounded-md gap-3 text-sm font-semibold px-3 py-2 transition hover:bg-gray-200 text-gray-700 border border-slate-200;

  &--light {
    @apply bg-white hover:bg-neutral-200;
  }

  &.btn-light {
    @apply bg-white border-gray-200 hover:bg-gray-100;
  }

  &.btn-primary {
    @apply border-[#2196F3] bg-[#2196F3] text-white hover:bg-[#2183f3] hover:border-[#2183f3];
  }

  &.btn-success {
    @apply border-[#41C568] bg-[#41C568] text-white hover:bg-[#43b465] hover:border-[#43b465];
  }

  &.btn-danger {
    @apply border-[#FF5252] bg-[#FF5252] text-white hover:bg-[#db5050] hover:border-[#db5050];
  }

  &.btn-dark {
    @apply border-gray-800 bg-gray-800 text-white hover:bg-gray-700 hover:border-gray-700;
  }
}
