.p-splitter {
  @apply bg-transparent border-0;
}

.p-splitter-panel {
  min-width: 300px;
  overflow: auto;
}

.p-splitter-gutter {
  @apply m-1 p-1 rounded-full;
}

.p-splitter-gutter-handle {
  @apply md:w-[6px];
}

.p-splitter-horizontal > .p-splitter-gutter > .p-splitter-gutter-handle {
  @apply h-[64px] w-[6px] bg-gray-600 rounded-md;
}

.p-splitter-vertical > .p-splitter-gutter > .p-splitter-gutter-handle {
  @apply w-[64px] h-[6px] bg-gray-600 rounded-md;
}
