.p-inputtext {
  @apply border border-slate-200 p-2 px-4 w-full rounded-[12px] min-h-[42px];
}

.p-inputnumber-buttons-stacked {
  position: relative;
  .p-inputnumber-button-group {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    height: 92%;
    top: 50%;
    transform: translateY(-50%);

    button {
      @apply border-l border-gray-200;
    }

    button:first-of-type {
      @apply border-b border-gray-200;
    }
  }
}

.chip-list-input-container {
  .p-chips {
    width: 100%;
  }

  .p-chips-multiple-container {
    padding: 4px 8px;
  }

  .invalid-chip {
    .p-chips {
      .p-chips-multiple-container {
        @apply border-red-500;
      }
    }
  }

  .p-chips-token {
    font-size: 0.85rem;
    font-weight: 500;
    @apply bg-blue-50 text-blue-500 py-[0.3rem] px-3;
  }
}

.p-inputtext,
.p-dropdown,
.p-dropdown-item,
.p-multiselect {
  @apply text-sm;
}

.p-dropdown-item {
  @apply flex items-center gap-1;
}

.p-dropdown-label {
  border: none;
  box-shadow: none;
  @apply flex items-center;
}

.placeholder-gray {
  .p-dropdown-label.p-placeholder {
    @apply text-gray-400;
  }
}
.p-dropdown-label.p-placeholder {
  color: inherit;
}

.p-inputwrapper,
.p-dropdown,
.p-multiselect,
.p-inputnumber {
  width: 100%;

  .p-multiselect-label {
    max-width: 58dvw;

    @media (min-width: 414px) {
      max-width: unset;
    }
  }
}

.p-dropdown {
  @apply border border-inherit rounded-[12px] min-h-[42px];

  .p-multiselect-token:not(:first-child) {
    margin-left: 1px;
  }
}

.p-multiselect {
  @apply border border-inherit rounded-[12px] h-[45px];

  .p-multiselect-token:not(:first-child) {
    margin-left: 1px;
  }

  .p-multiselect-label {
    @apply pl-2 w-full;
  }

  .p-multiselect-token {
    font-size: 0.85rem;
    font-weight: 500;
    @apply bg-blue-50 text-blue-500 py-[0.42rem] px-3 translate-y-[-1px];
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 0;
  background: white;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  @apply bg-transparent hover:bg-gray-100;
}

.input-field {
  border-width: 2px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  @apply border-neutral-200 py-3 px-4 rounded-md outline-none focus:border-sky transition-all;

  &:hover {
    @apply border-neutral-300;
  }

  &:focus {
    @apply border-sky;
  }

  &.ng-touched.ng-invalid {
    @apply border-red-500;
  }
}

.p-column-filter-overlay {
  @apply w-64 drop-shadow-sm;

  .p-column-filter-constraint,
  .p-column-filter-buttonbar {
    @apply p-4;
  }

  .p-column-filter-constraint {
    @apply pb-0;

    .p-multiselect {
      @apply border border-slate-200;
    }
  }

  .p-column-filter-buttonbar {
    @apply gap-2;

    button {
      @apply text-sm px-3 py-1 w-full bg-gray-100 border border-slate-200 hover:bg-sky hover:border-sky hover:text-gray-800;

      &.p-button-outlined {
        @apply bg-white hover:bg-gray-100 hover:border-slate-200;
      }
    }
  }
}
