.tab-view {
  height: 100%;

  .p-tabview {
    display: flex;
    flex-direction: column;
  }

  .p-tabview-panels {
    @apply flex-grow rounded-md p-3 border border-slate-200;

    p-tabpanel {
      @apply flex flex-col flex-1;
    }
  }

  .p-tabview-nav-container {
    @apply sticky top-0 z-[1];
  }

  .p-tabview-nav-content {
    @apply rounded-md mb-1 border border-slate-200;
  }

  .p-tabview-nav {
    @apply rounded-md p-1 pb-[6px] gap-1 overflow-auto flex flex-wrap;

    .p-highlight .p-tabview-nav-link {
      @apply bg-blue-50 text-blue-600;
    }
  }

  .p-tabview-nav-link {
    @apply p-0 m-0 font-medium;

    div {
      @apply text-base text-gray-800 py-2 px-3 rounded-md hover:bg-gray-50;

      span {
        @apply font-medium
      }
    }
  }
}
