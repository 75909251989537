.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  i,
  span {
    @apply text-neutral-700;
  }
}

.p-overlaypanel-content {
  @apply bg-gray-800 py-2 px-3 rounded-md;
}
