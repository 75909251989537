.offen {
  .p-chip {
    @apply bg-[#c864f3] text-white;
  }
}

:ng-deep {
  .mat-mdc-standard-chip {
    background: red !important;
  }

  .mdc-evolution-chip-set__chips {
    flex-wrap: nowrap !important;
  }
}
