@use "@angular/material" as mat;
@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$blue-palette, 700, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

.avatar {
  //h-[42px] w-[42px] uppercase font-bold text-lg
  @apply flex items-center justify-center rounded-2xl bg-blue-100/20 text-blue-600 cursor-pointer focus:bg-blue-100/70;
}

@import "./components/input";
@import "./components/spinner";
@import "./components/button";
@import "./components/calendar";
@import "./components/checkbox";
@import "./components/tooltip";
@import "./components/chip";
@import "./components/menu";
@import "./components/tab-list";
@import "./components/scrollbar";
@import "./components/data-table";
@import "./components/toast";
@import "./components/splitter";

@include mat.all-component-themes($my-theme);
