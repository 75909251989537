.p-checkbox-box,
.p-checkbox-icon,
.p-checkbox {
  width: 18px;
  height: 18px;
}

.p-checkbox-box {
  @apply border-2 border-neutral-500 rounded-sm;

  &.p-highlight {
    @apply border-sky bg-sky;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  @apply drop-shadow-none;
}

.p-icon {
  height: 14px;
  width: 14px;
}
