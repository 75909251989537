@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primeicons/primeicons.css";
@import "./theme/index.scss";

:root {
  font-size: 16px;
  font-family: "Roboto", sans-serif !important;
}

html,
body {
  height: 100%;
}

body {
  background: #f8f9fd;
  overflow: hidden;
  scroll-behavior: smooth;
}

app-root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #9d9da0;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}
